
import { computed, defineComponent } from "vue";
import {
  formatNumber,
  paymentRemain,
  paymentSum,
  totalInt,
} from "@/graphql/utils/utils";
import { activeActivity } from "@/plugins/i18n";
import { getDiscountValues } from "@/graphql/ticket/ticket";
import { Ticket } from "@/graphql/types";

export default defineComponent({
  name: "CreditTicket",
  props: ["tickets", "modelValue"],
  emits: ["update:modelValue"],
  setup(props) {
    return {
      paymentSum,
      paymentRemain,
      totalInt,
      currency: activeActivity.value.currencySymbol,
      getDiscountValues,
      formatNumber,
      resume: computed(() => {
        return (props.tickets as Ticket[]).reduce(
          (cum, ticket) => {
            const { total, totalNet, discount, sold, received } =
              getDiscountValues(ticket);
            cum.total += total;
            cum.totalNet += totalNet;
            cum.discount += discount;
            cum.sold += sold;
            cum.received += received;
            return cum;
          },
          { total: 0, totalNet: 0, discount: 0, sold: 0, received: 0 }
        );
      }),
    };
  },
});
