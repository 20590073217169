import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_NumberFormatter = _resolveComponent("NumberFormatter")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    "data-key": "id",
    value: _ctx.tickets,
    showGridlines: "",
    paginator: false,
    class: "p-datatable-sm",
    rows: _ctx.tickets.length,
    "row-hover": true,
    selection: _ctx.modelValue[0],
    "onUpdate:selection": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', [$event]))),
    onRowClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', [$event.data]))),
    "auto-layout": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        selectionMode: "single",
        headerStyle: "width: 2rem"
      }),
      _createVNode(_component_Column, {
        field: "createdAt",
        header: _ctx.$t('date'),
        dataType: "date"
      }, {
        body: _withCtx(({ data }) => [
          _createTextVNode(_toDisplayString(_ctx.$d(data.createdAt, "long")), 1)
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "number",
        header: _ctx.$t('rapport.ticketNumber'),
        dataType: "numeric"
      }, {
        body: _withCtx(({ data }) => [
          (data.number)
            ? (_openBlock(), _createBlock(_component_NumberFormatter, {
                key: 0,
                value: data.number
              }, null, 8, ["value"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "customer.name",
        header: _ctx.$t('rapport.client')
      }, null, 8, ["header"]),
      _createVNode(_component_Column, {
        footer: `${_ctx.formatNumber(_ctx.resume.total)} ${_ctx.currency}`,
        header: _ctx.$t('rapport.amount'),
        dataType: "numeric"
      }, {
        body: _withCtx(({ data }) => [
          _createVNode(_component_NumberFormatter, {
            value: _ctx.totalInt(data.movements)
          }, null, 8, ["value"]),
          _createTextVNode(" " + _toDisplayString(_ctx.currency), 1)
        ]),
        _: 1
      }, 8, ["footer", "header"]),
      _createVNode(_component_Column, {
        footer: `${_ctx.formatNumber(_ctx.resume.discount)} ${_ctx.currency}`,
        header: _ctx.$t('payment.discount'),
        dataType: "numeric"
      }, {
        body: _withCtx(({ data }) => [
          _createVNode(_component_NumberFormatter, {
            value: data.amount,
            discount: ""
          }, null, 8, ["value"]),
          (data.percentage)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(" ("),
                _createVNode(_component_NumberFormatter, {
                  value: data.percentage,
                  discount: ""
                }, null, 8, ["value"]),
                _createTextVNode("%) ")
              ], 64))
            : _createCommentVNode("", true)
        ]),
        filter: _withCtx(({ filterModel }) => [
          _createVNode(_component_InputNumber, {
            showButtons: "",
            buttonLayout: "horizontal",
            step: 1,
            decrementButtonClass: "p-button-danger",
            incrementButtonClass: "p-button-success",
            incrementButtonIcon: "pi pi-plus",
            decrementButtonIcon: "pi pi-minus",
            modelValue: filterModel.value,
            "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
            "max-fraction-digits": 20,
            min: 0
          }, null, 8, ["modelValue", "onUpdate:modelValue"])
        ]),
        filterclear: _withCtx(({ filterCallback }) => [
          _createVNode(_component_Button, {
            type: "button",
            onClick: ($event: any) => (filterCallback()),
            class: "p-button-warning p-button-sm",
            label: _ctx.$t('prime.clear')
          }, null, 8, ["onClick", "label"])
        ]),
        filterapply: _withCtx(({ filterCallback }) => [
          _createVNode(_component_Button, {
            label: _ctx.$t('search'),
            type: "button",
            icon: "pi pi-search",
            class: "p-button-sm",
            onClick: ($event: any) => (filterCallback())
          }, null, 8, ["label", "onClick"])
        ]),
        _: 1
      }, 8, ["footer", "header"]),
      _createVNode(_component_Column, {
        footer: `${_ctx.formatNumber(_ctx.resume.totalNet)} ${_ctx.currency}`,
        header: _ctx.$t('rapport.netTotal'),
        dataType: "numeric"
      }, {
        body: _withCtx(({ data }) => [
          _createVNode(_component_NumberFormatter, {
            value: _ctx.getDiscountValues(data).totalNet
          }, null, 8, ["value"]),
          _createTextVNode(" " + _toDisplayString(_ctx.currency), 1)
        ]),
        _: 1
      }, 8, ["footer", "header"]),
      _createVNode(_component_Column, {
        footer: `${_ctx.formatNumber(_ctx.resume.received)} ${_ctx.currency}`,
        header: _ctx.$t('rapport.payed'),
        dataType: "numeric"
      }, {
        body: _withCtx(({ data }) => [
          _createVNode(_component_NumberFormatter, {
            value: _ctx.paymentSum(data.payments)
          }, null, 8, ["value"]),
          _createTextVNode(" " + _toDisplayString(_ctx.currency), 1)
        ]),
        _: 1
      }, 8, ["footer", "header"]),
      _createVNode(_component_Column, {
        footer: `${_ctx.formatNumber(_ctx.resume.sold)} ${_ctx.currency}`,
        field: "sold",
        header: _ctx.$t('payment.sold'),
        dataType: "numeric"
      }, {
        body: _withCtx(({ data }) => [
          _createVNode(_component_NumberFormatter, {
            value: _ctx.getDiscountValues(data).sold
          }, null, 8, ["value"]),
          _createTextVNode(" " + _toDisplayString(_ctx.currency), 1)
        ]),
        _: 1
      }, 8, ["footer", "header"])
    ]),
    _: 1
  }, 8, ["value", "rows", "selection"]))
}